<template>
	<div class="features-domain-monitoring">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10 py-6 text-center">
						<h1 class="display-3 text-gradient fw-bold mb-5">Get instant domain alerts</h1>

						<h3 class="fw-normal fs-5 mb-4">You'll receive alerts when any change is detected in a domain:</h3>

						<div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 align-items-center mb-5">
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">enters expiration phase</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">is available for registration</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">registration is renewed</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">subdomain is added</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">name servers are changed</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">subdomain is removed</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">domain is transferred</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1 text-muted">other statuses and changes</div>
							</div>
						</div>

						<p class="">
							Start with a <button class="btn btn-primary px-5 mx-2" @click="focusSearch">🔍 Domain search</button> or learn more ⤵
						</p>
					</div>
				</div>
			</div>
		</section>


		<section class="bg-dark py-6">
			<div class="container-fluid">

				<div class="row text-center">
					<div class="col-6 col-md-4 col-lg-3 my-4">
						<h4 class="text-light display-5 mb-3">{{ stats['monitoring-whois-checks'].toLocaleString() }}</h4>
						<p class="text-gray lead my-0">checks made</p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 my-4">
						<h4 class="text-light display-5 mb-3">{{ stats['notifications-sent'].toLocaleString() }}</h4>
						<p class="text-gray lead my-0">alerts sent</p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 my-4">
						<h4 class="text-light display-5 mb-3">{{ (1173).toLocaleString() }}</h4>
						<p class="text-gray lead my-0">domains watched</p>
					</div>

					<div class="col-6 col-md-4 col-lg-3 my-4 d-md-none d-lg-block">
						<h4 class="text-light display-5 mb-3">99.99%</h4>
						<p class="text-gray lead my-0">uptime</p>
					</div>
				</div>
			</div>
		</section>


		<section class="bg-white py-6">
			<div class="container">
				<div class="row">
					<div class="col-md-8 my-3">
						<h2 class="display-5 mb-3">💥 Powerful domain monitoring</h2>

						<p class="lead">From the moment you click the button <strong>Monitor domain</strong> on any domain, the platform starts doing the work for you:</p>

						<ul class="nicer-list">
							<li>constantly check <abbr title="WHOIS is a query and response protocol that is used for querying databases that store information about domain names">WHOIS</abbr> servers</li>
							<li>constantly check the <abbr title="NS are pecialized servers in handling queries regarding the location of a domain name's various services: email, hosting, text records, and more">Nameservers</abbr> for DNS Records</li>
							<li>scour domain marketplaces for sale-related activity</li>
							<li>check <a href="https://www.icann.org/resources/pages/help/dndr/udrp-en" target="_blank"><abbr title="Uniform Domain-Name Dispute-Resolution Policy (UDRP) is a process established by the Internet Corporation for Assigned Names and Numbers (ICANN) for the resolution of disputes regarding the registration of internet domain names">UDRP</abbr></a> websites for mentions of the domain <small class="text-muted">(beta)</small></li>
						</ul>

						<p>In case there's an update, we'll send you instantly an alert with detailed information about the update. Alerts can also be sent daily, how you prefer.</p>
					</div>

					<div class="col-md-4 my-3">

						<p class="text-muted"><span class="badge bg-danger text-white me-1">⚪️ LIVE</span> changes detected in real-time ↓</p>

						<div class="border border-light rounded px-0" id="domain-changes">
							<router-link v-for="change in recents" :key="`domain-change-${change.id}`" :to="`/${change.domain}/history`" class="d-block border border-bottom border-light p-2 bg-info-lighter" :class="{'bg-danger-lighter': change.text.includes('expiration phase'), 'bg-success-lighter': change.text.includes('registration')}">
								<h5 class="fs-5 mb-1 text-dark">{{ change.domain }}</h5>
								<p class="mb-1 text-muted">{{ change.text }}</p>
								<strong class="text-dark">{{ change.date_string }}</strong>
							</router-link>
						</div>

					</div>
				</div>

			</div>
		</section>


		<section class="container py-6">

			<div class="row gx-5">
				<div class="col-md-6 my-3">
					<h2 class="text-center mb-4">Before</h2>

					<div class="border rounded">
						<img src="/images/how-before.jpg" class="img-fluid" alt="Domains App - Before using it">
						<div class="bg-light p-2">
							<p class="mb-2">Of course these tools work, but they require so much time and manual work.</p>
							<p class="mb-2">Before using Domains App, our customers told us stories about tracking everything in Excel or paper, adding reminder in calendars or missing crucial domain-related events because of no alerts.</p>
							<p class="mb-0">After seeing all these, we built the app to improve these workflows, and now you can take advantage of it too!</p>
						</div>
					</div>
				</div>
				<div class="col-md-6 my-3">

					<h2 class="text-center text-gradient mb-4">After</h2>

					<div class="card mb-3">
						<div class="row g-0">
							<div class="col-7">
								<div class="card-body">
									<h4 class="card-title"><small>1️⃣</small> Find the domain</h4>

									<p class="card-text">Search for any domain in our app</p>
								</div>
							</div>
							<div class="col-5">
								<img src="/images/how-1.png" class="img-fluid rounded-end" alt="Domains App - Search domains">
							</div>
						</div>
					</div>

					<div class="card mb-3">
						<div class="row g-0">
							<div class="col-7">
								<div class="card-body">
									<h4 class="card-title"><small>2️⃣</small> Start monitoring</h4>

									<p class="card-text">Just click the <strong>Monitor domain</strong> button</p>
								</div>
							</div>
							<div class="col-5">
								<img src="/images/how-2.png" class="img-fluid rounded-end" alt="Domains App - Monitor domain">
							</div>
						</div>
					</div>

					<div class="card">
						<div class="row g-0">
							<div class="col-7">
								<div class="card-body">
									<h4 class="card-title"><small>3️⃣</small> Receive notifications</h4>

									<p class="card-text">Alerts start coming in when we detect updates</p>
								</div>
							</div>
							<div class="col-5">
								<img src="/images/how-3.png" class="img-fluid rounded-end" alt="Domains App - Notifications for domain">
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>


		<section class="bg-white py-6">

			<div class="container">

				<h3 class="text-center mb-5">Frequently asked questions</h3>

				<div class="row justify-content-center">
					<div class="col-md-10 col-lg-8">

						<h5>How ofter are domains monitored for changes?</h5>
						<p class="lead mb-5">Our platform uses a dynamic interval for checking domains for changes. The standard monitoring interval is 1 hour, and the interval is increased towards 1-5 minutes if the domain is expected to change soon (in case of expiry, transfers, or other signals)</p>

						<h5>Can I monitor domains for free?</h5>
						<p class="lead">Yes, anyone with a registered account can monitor 1 domain for free. If you need to monitor more, check our <router-link to="/plans">available Plans</router-link></p>

					</div>
				</div>

				<p class="text-center mt-5">
					<button class="btn btn-primary px-5" @click="focusSearch">🔍 Search for a domain now</button>
				</p>

			</div>

		</section>

	</div>
</template>

<style scoped>
#domain-changes {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	max-height: 400px;
	overflow-x: auto;
}
</style>

<script>
import { mapState } from 'vuex'
import { formatDistanceToNowStrict } from 'date-fns'

import domainsApi from "@/api.js"

export default {
	name: 'FeatureMonitoring',
	metaInfo() {
		const url = 'https://dmns.app/monitor-domains'
		const title = 'Monitor Domains - Track domain expiry and subdomains'
		const description = 'Keep an eye on any domain on the internet: receive instant alerts when a domain expires, is renewed or subdomains are changed'

		return {
			title,
			link: [
				{ rel: 'canonical', href: url }
			],
			meta: [
				{ name: 'description', content: description },
				{ property: 'og:site_name', content: 'Domains App'},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: title },
				{ property: 'og:url', content: url },
				{ property: 'og:description', content: description }
			]
		}
	},
	data() {
		return {
			recents: [],
			$domainChanges: null,
		}
	},
	computed: {
		...mapState([ 'isMobile', 'stats' ]),
	},
	created() {
		this.$store.dispatch('updateStats')

		domainsApi.get('/monitoring/recent-changes').then(({ data }) => {
			const recents = data
								//.filter(d => d.domain.endsWith('.com') || d.domain.endsWith('.co') || d.domain.endsWith('.app'))

			this.recents.push(...recents)
		})
	},
	mounted() {
		this.$domainChanges = document.getElementById('domain-changes')
		window.addEventListener('scroll', this.scrollWindow)
	},
	methods: {
		formatDistanceToNowStrict,

		scrollWindow() {
			this.$domainChanges.scrollTop = window.scrollY - this.$domainChanges.offsetTop + window.innerHeight - 250
		},
		focusSearch() {
			const $search = document.querySelector(`.dmns-search.search-${this.isMobile ? '' : 'not-'}mobile`)
			$search.focus()
		}
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollWindow)
	},
}
</script>
