<template>
	<div class="page-plans">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 my-5 text-center">
						<h1 class="display-5">Plans &amp; Pricing</h1>
						<p v-if="auth" class="lead text-gray">How many domains you want to monitor?</p>
						<p v-else class="lead text-gray">Start monitoring a domain for free, pick a plan later</p>

						<p v-if="!auth" class="mb-0">
							<router-link to="/account/register" class="btn btn-primary px-5">Create your account</router-link>
						</p>
					</div>
				</div>
			</div>
		</section>

		<section class="bg-white pt-6 pb-1">

			<div class="container">

				<table class="table table-hover mb-5">
					<thead>
						<tr>
							<th></th>
							<th class="px-4">
								<img src="/images/plan-basic.png" alt="Domains App Basic" class="img-fluid" />
							</th>
							<th class="px-3">
								<img src="/images/plan-pro.png" alt="Domains App Basic" class="img-fluid" />
							</th>
							<th>
								<img src="/images/plan-business.png" alt="Domains App Basic" class="img-fluid" />
							</th>
						</tr>
						<tr>
							<td class="border-0" valign="top">
								<div class="form-check form-switch">
									<input class="form-check-input" type="checkbox" v-model="yearly" id="plans-yearly">
									<label class="form-check-label" for="plans-yearly">Yearly pricing (2 months free)</label>
								</div>
							</td>
							<td class="text-center border-0" valign="top" width="18%">
								<h5 class="subtitle">Basic</h5>
								<h4>${{ plans.basic[yearly ? 'year' : 'month'].price }}<small class="text-muted">/{{ yearly ? 'year' : 'month' }}</small></h4>
								<span v-if="account && account.plan === 'basic'" class="badge bg-blue-100 text-blue-500">Current plan</span>
							</td>
							<td class="text-center border-0" valign="top" width="18%">
								<h5 class="subtitle">Pro</h5>
								<h4>${{ plans.pro[yearly ? 'year' : 'month'].price }}<small class="text-muted">/{{ yearly ? 'year' : 'month' }}</small></h4>
								<span v-if="account && account.plan === 'pro'" class="badge bg-blue-100 text-blue-500">Current plan</span>
							</td>
							<td class="text-center border-0" valign="top" width="18%">
								<h5 class="subtitle">Business</h5>
								<h4>${{ plans.business[yearly ? 'year' : 'month'].price }}<small class="text-muted">/{{ yearly ? 'year' : 'month' }}</small></h4>
								<span v-if="account && account.plan === 'business'" class="badge bg-blue-100 text-blue-500">Current plan</span>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								<router-link to="/monitor-domains">Monitor domains</router-link>
							</td>
							<td class="text-center">
								<strong>10</strong>
							</td>
							<td class="text-center">
								<strong>50</strong>
							</td>
							<td class="text-center">
								<strong>250</strong>
							</td>
						</tr>
						<tr>
							<td class="ps-3">
								↳ Email notifications
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td class="ps-3">
								↳ Slack notifications
							</td>
							<td class="text-center">
								<strong class="text-danger">-</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td class="ps-3">
								↳ Text/SMS
							</td>
							<td class="text-center">
								<strong class="text-danger">-</strong>
							</td>
							<td class="text-center">
								<strong class="text-danger">-</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td>
								Advanced WHOIS Lookup
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td>
								Detailed DNS Records discovery
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td>
								Domain history (WHOIS, DNS)
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td>
								Bookmark folders
							</td>
							<td class="text-center">
								<span>2</span>
							</td>
							<td class="text-center">
								<span>10</span>
							</td>
							<td class="text-center">
								<strong>50</strong>
							</td>
						</tr>
						<tr>
							<td>
								Email support
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr>
							<td>
								API Access
							</td>
							<td class="text-center">
								<strong class="text-danger">-</strong>
							</td>
							<td class="text-center">
								<strong class="text-danger">-</strong>
							</td>
							<td class="text-center">
								<strong class="text-success">✓</strong>
							</td>
						</tr>
						<tr v-if="account && !account.stripe_subscription_id">
							<td>
								
							</td>
							<td class="text-center">
								<a :href="`${url}account/${auth.id}/stripe-checkout/basic/${plans.basic[yearly ? 'year' : 'month'].id}`" class="btn btn-primary">Select plan</a>
							</td>
							<td class="text-center">
								<a :href="`${url}account/${auth.id}/stripe-checkout/pro/${plans.pro[yearly ? 'year' : 'month'].id}`" class="btn btn-primary">Select plan</a>
							</td>
							<td class="text-center">
								<a :href="`${url}account/${auth.id}/stripe-checkout/business/${plans.business[yearly ? 'year' : 'month'].id}`" class="btn btn-primary">Select plan</a>
							</td>
						</tr>
					</tbody>
				</table>

				<p class="text-center mb-5">
					<router-link v-if="!auth" to="/account/register" class="btn btn-primary px-5">Create your account</router-link>
					<a v-else-if="account && account.stripe_subscription_id" :href="`${url}account/${account.stripe_customer_id}/stripe-customer-portal`" class="btn btn-primary px-5">Manage plan &amp; billing info</a>
				</p>

				<div class="row mb-5">
					<div class="col-md-6 my-2">
						<div class="rounded bg-cyan-100 p-4">
							<h4>👋🏼 Just want to try it out?</h4>
							<p class="mb-0">Free accounts can monitor 1 domain. See how it works, then upgrade to another plan.</p>
						</div>
					</div>
					<div class="col-md-6 my-2">
						<div class="rounded bg-teal-100 p-4">
							<h4>🪨 Need a higher custom plan?</h4>
							<p class="mb-0">If you need to monitor more domains or custom access to API, <a href="https://cal.com/andrei/domains-app" target="_blank">send us a message</a>.</p>
						</div>
					</div>
				</div>

				<p class="small text-muted">Domains App collects sales taxes in states/countries where it is required by law. Stated prices do not include these taxes.</p>

			</div>

		</section>
		
		<section class="py-6">

			<div class="container">

				<h3 class="text-center mb-4">FAQs</h3>

				<div class="row justify-content-center">
					<div class="col-xl-8 col-lg-10">

						<div class="accordion accordion-flush mb-4" id="plans-faqs">
							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingZero">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseZero" aria-expanded="false" aria-controls="flush-collapseZero">
										Is Domains App built for people like me?
									</button>
								</h2>
								<div id="flush-collapseZero" class="accordion-collapse collapse" aria-labelledby="flush-headingZero" data-bs-parent="#plans-faqs">
									<div class="accordion-body">
										<p class="mb-2">Let's find out.</p>
										<p class="mb-2">Are you searching for the perfect domain for your project? Do you identify as a domainer? Do you want to know when a domain will expire? Do you want to be notified when any change happens at a domain of interest?</p>
										<p class="mb-0">If you answered yes to ANY of those descriptors, then yep, Domains App is built for someone just like you.</p>
									</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingOne">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
										How ofter are domains monitored for changes?
									</button>
								</h2>
								<div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Our platform uses a dynamic interval for checking domains for changes. The standard monitoring interval is 1 hour, and the interval is increased towards 1-5 minutes if the domain is expected to change soon (in case of expiry, transfers, and more)</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingTwo">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
										Do I need to enter my payment details to sign up?
									</button>
								</h2>
								<div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#plans-faqs">
									<div class="accordion-body">No. You can sign up for the free account, and when you decide you want to monitor more domains or API access, you will need to pick a plan and enter your payment details.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingThree">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
										Can I cancel my account at any time?
									</button>
								</h2>
								<div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Yes. If you ever decide that Domains App is not the best tool for you, simply cancel your account.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingFour">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
										Do you offer any discounted plans?
									</button>
								</h2>
								<div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Yes, we offer a 10% discount on annual plans when they are paid upfront.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingFive">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
										Can I change my plan later on?
									</button>
								</h2>
								<div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Absolutely! You can upgrade or downgrade your plan at any time.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingSix">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
										Why is this a subscription?
									</button>
								</h2>
								<div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Constant maintenance</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingSeven">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
										Can I swap the domains I monitor?
									</button>
								</h2>
								<div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#plans-faqs">
									<div class="accordion-body">Yes, you can stop monitoring a domain anytime, then start monitoring another one. The limit in a plan is for the number of domains monitored at the same time.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingNine">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
										What happens if my payment fails?
									</button>
								</h2>
								<div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#plans-faqs">
									<div class="accordion-body">For example, an expired credit card. Our payment processor, Stripe, will retry your payment method, multiple times if necessary. If your payment method does not suceed after a week you will be downgraded to the free plan.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingEight">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
										What happens when I cancel my plan or downgrade?
									</button>
								</h2>
								<div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#plans-faqs">
									<div class="accordion-body">If you are monitoring more than 1 domain, we will remove the domains from monitoring list, except one. On the free account, you can monitor only one domain.</div>
								</div>
							</div>

							<div class="accordion-item">
								<h2 class="accordion-header" id="flush-headingTen">
									<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
										If I don’t like Domains App, can I request a refund?
									</button>
								</h2>
								<div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#plans-faqs">
									<div class="accordion-body">
										<p class="mb-2">We’d hate to see you go, but we totally understand that sometimes things just don’t work out.</p>
										<p class="mb-2">No matter if you’re on a monthly or annual plan, if you realize Domains App isn’t the right fit for you we can give you a refund as long as you’re within the first 30 days of the date you signed up for your account.</p>
										<p class="mb-0">Our team will cancel your account and send that refund along immediately (but it will take 3-5 days to show up on your credit or debit card account).</p>
									</div>
								</div>
							</div>
						</div>

						<div class="text-center mt-6">
							<h2 class="mb-4">Want to see Domains App in action?</h2>

							<p class="lead mb-4">When you depend on a platform that powers your business, we know choosing a new one is a big deal. Join one of our team members on a live tour and get the answers you need to see if we’re a good fit for you.</p>

							<p>
								<a href="https://cal.com/andrei/domains-app" target="_blank" class="btn btn-primary">Request a demo</a>
							</p>
						</div>

					</div>
				</div>

			</div>

		</section>

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Vue from 'vue'

export default {
	name: 'Features',
	components: { },
	data() {
		return {
			url: 'http://localhost:808/',
			yearly: false
		}
	},
	computed: {
		...mapState([ 'account', 'apiUrl', 'plans' ]),
		...mapGetters([ 'auth' ]),
	},
	created() {
		this.$store.dispatch('ev', {
			event: 'View Plans',
			data: { notify: true }
		})
		this.$store.dispatch('accountInfo')
		this.url = this.apiUrl

		if (this.$route.query.alert) {
			Vue.toasted.show(this.$route.query.alert)
		}
	},
}
</script>
