<template>
	<div class="home">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-8 my-6 text-center">
						<h1 class="display-2">Features</h1>
						<p class="lead">Domains App is filled with features, perks and goodies to help you be more productive</p>
					</div>
				</div>
			</div>
		</section>


		<section class="container pb-5">

				<div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xxl-4">

					<div class="col my-3">
						<router-link to="/monitor-domains" class="card">
							<div class="card-body">
								<span class="fs-1">📸</span>
								<h3 class="card-title text-dark mb-3">Domain monitoring</h3>

								<p class="card-text text-dark mb-2">Timely alerts for changes in domains.</p>
								<p class="card-text text-dark">Be notified when we detect that a domain expires, owner changes or subdomains are added to it.</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/features/domain-search" class="card">
							<div class="card-body">
								<span class="fs-1">🔍</span>
								<h3 class="card-title text-dark mb-3">Domain search</h3>

								<p class="card-text text-dark mb-2">Find the perfect domain for your project.</p>
								<p class="card-text text-dark">Search any keyword, and get instant stats on which extensions (TLDs) are available, for sale or taken.</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/features/domain-search" class="card">
							<div class="card-body">
								<span class="fs-1">🕵️</span>
								<h3 class="card-title text-dark mb-3">Detailed domain info</h3>

								<p class="card-text text-dark mb-2">Discover all data behing a domain.</p>
								<p class="card-text text-dark">See all WHOIS & DNS Records data like: domain owner, important dates, subdomains, historical records.</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/browser-extension" class="card">
							<div class="card-body">
								<span class="fs-1">🧩</span>
								<h3 class="card-title text-dark mb-3">Domain info extension</h3>

								<p class="card-text text-dark mb-2">Domain info as you browse the internet.</p>
								<p class="card-text text-dark">Use the Domain Info extension directly in your browser to get owner details, subdomains list or other important infos about any domain on the internet.</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/features/notes-and-collections" class="card">
							<div class="card-body">
								<span class="fs-1">📝</span>
								<h3 class="card-title text-dark mb-3">Notes &amp; comments</h3>

								<p class="card-text text-dark mb-2">Add private notes.</p>
								<p class="card-text text-dark">Keep private notes on any domain of interest, and read them in the domain timeline.</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/features/notes-and-collections" class="card">
							<div class="card-body">
								<span class="fs-1">🗃</span>
								<h3 class="card-title text-dark mb-3">Collections</h3>

								<p class="card-text text-dark mb-2">Organize domains in collections.</p>
								<p class="card-text text-dark">Keep track of domains of interest and organize them in different collections. Maybe one collection for your project, one for interesting domains, one for competitors. How you prefer</p>
							</div>
						</router-link>
					</div>

					<div class="col my-3">
						<router-link to="/tlds" class="card">
							<div class="card-body">
								<span class="fs-1">🌐</span>
								<h3 class="card-title text-dark mb-3">All TLDs info</h3>

								<p class="card-text text-dark mb-2">Discover new domain extensions</p>
								<p class="card-text text-dark">We keep a list of all available domain extensions (.com, .net, .ai, etc) on the internet, check it out. Do you know when the first domain extension was created?</p>
							</div>
						</router-link>
					</div>

				</div>

		</section>


	</div>
</template>

<style scoped>
.bg-1 {
	background-color: #e0f2fe;
}
.bg-2 {
	background-color: #ffe4e6;
}
.bg-3 {
	background-color: #d1fae5;
}

#domain-changes {
	padding-left: 0.8rem;
	padding-right: 0.8rem;
	max-height: 400px;
	overflow-x: auto;
}
</style>

<script>
import domainsApi from "@/api.js"
import { formatDistanceToNowStrict } from 'date-fns'

export default {
	name: 'Features',
	components: { },
	data() {
		return {
			recents: [],
			$domainChanges: null,
		}
	},
	created() {
		domainsApi.get('/monitoring/recent-changes').then(({ data }) => {
			const recents = data
								//.filter(d => d.domain.endsWith('.com') || d.domain.endsWith('.co') || d.domain.endsWith('.app'))

			this.recents.push(...recents)
		})
	},
	mounted() {
		this.$domainChanges = document.getElementById('domain-changes')
		window.addEventListener('scroll', this.scrollWindow)
	},
	methods: {
		formatDistanceToNowStrict,
		scrollWindow() {
			this.$domainChanges.scrollTop = window.scrollY
		},
	},
	destroyed() {
		window.removeEventListener('scroll', this.scrollWindow)
	},
}
</script>
