<template>
	<div class="home">

		<section class="page-head no-bg">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-lg-10 py-6 text-center">
						<h1 class="display-2 text-gradient fw-bold mb-5">Instant domain search</h1>

						<h3 class="fw-normal fs-5 mb-4">Search any keyword and discover all data behind a domain:</h3>

						<div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 align-items-center mb-5">
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">keyword stats</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">domain availability</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">expiry date</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">domain owner</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">all subdomains</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">historical data</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">sale or udrp info</div>
							</div>
							<div class="col my-2">
								<div class="bg-white rounded py-3 px-1">whois &amp; dns records</div>
							</div>
						</div>

						<p class="">
							Start with a <button class="btn btn-primary px-5 mx-2" @click="focusSearch">🔍 Domain search</button> or learn more ⤵
						</p>
					</div>
				</div>
			</div>
		</section>


		<section class="bg-white py-6">

			<div class="container">

				<div class="row justify-content-center">

					<div class="col-lg-8 text-center">

						<p class="lead text-gray">Quickly search a keyword</p>
						<p class="lead text-gray">We'll show in which TLDs is available</p>
						<p class="lead text-gray">color coded for availability</p>
						<p class="lead text-gray">showing if domains are for sale</p>
						<p class="lead text-gray">Keyword stats</p>
						<p class="lead text-gray">expires soon</p>
						<p class="lead text-gray">see owner info</p>

					</div>

				</div>

			</div>

		</section>

	</div>
</template>

<script>

export default {
	name: 'FeatureSearch',
	metaInfo() {
		const url = 'https://dmns.app/features/domain-search'
		const title = 'Instant domain search & info'
		const description = 'Type any word and we\'ll show you instantly it\'s availability across many domain extensions. If it\'s taken, WHOIS and DNS info is shown'

		return {
			title,
			link: [
				{ rel: 'canonical', href: url }
			],
			meta: [
				{ name: 'description', content: description },
				{ property: 'og:site_name', content: 'Domains App'},
				{ property: 'og:type', content: 'website' },
				{ property: 'og:title', content: title },
				{ property: 'og:url', content: url },
				{ property: 'og:description', content: description },
			]
		}
	},
	components: { }
}
</script>
